body {
  margin: 0;
  background: #ECF0EC;
}

@font-face {
  font-family: "Noctis-Bold";
  src: url("/fonts/Noctis-Bold.woff2");
}
@font-face {
  font-family: "Noctis-Bold-Italic";
  src: url("/fonts/Noctis-Bold-Italic.woff2");
}
@font-face {
  font-family: "Noctis-Regular";
  src: url("/fonts/Noctis-Regular.woff2");
}
@font-face {
  font-family: "Noctis-Italic";
  src: url("/fonts/Noctis-Italic.woff2");
}
@font-face {
  font-family: "Work Sans";
  src: url("/fonts/WorkSans-VariableFont_wght.ttf");
}
.Noctis-Bold {
  font-family: "Noctis-Bold", sans-serif;
  font-weight: 400;
}
.Noctis-Bold-Italic {
  font-family: "Noctis-Bold-Italic", sans-serif;
  font-weight: 400;
}
.Noctis-Regular {
  font-family: "Noctis-Regular", sans-serif;
  font-weight: 400;
}
.Noctis-Regular em {
  font-family: "Noctis-Italic", sans-serif;
  font-style: normal;
}
.Noctis-Regular strong,
.Noctis-Regular b {
  font-family: "Noctis-Bold", sans-serif;
  font-weight: 400;
}
.Noctis-Regular strong em,
.Noctis-Regular b em {
  font-family: "Noctis-Bold-Italic", sans-serif;
  font-weight: 400;
}
.Noctis-Italic {
  font-family: "Noctis-Italic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.gridjs-container{
  padding: 0;
}